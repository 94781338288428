import React from "react"
import Blog from "../images/blog.svg"
import News from "../images/news.svg"
import Webinar from "../images/webinar.svg"
import Cheat_sheet from "../images/Cheat_sheet.png"
import Infographic from "../images/Infographic.png"
import Video from "../images/video.png"
//import Ebook from "../images/ebook.svg"
import { Link } from "gatsby"


const ResourceTag = ({ type, item, className = '', url = false }) => {

  //console.log(type)
  
  // so we have key, label and icon that we need to produce
  
  // console.log(type, item?.eBookType)

  const element = {
    label: "",
    key:"",
    src:Blog,
  }
  
  //let icon = ''
  switch (type) {
    case 'Blog': 
      element.label = "Blog"
      element.src = Blog
      break;
    case 'News': 
    element.label = "News"
      element.src = News

      break;
    case 'Press releases': 
      //icon = Blog; 
      element.label = "Press releases"
      element.src = Blog
    break;
    case 'Webinar': 
      element.label = "Webinar"
      element.src = Webinar
      //icon = Webinar; 
    break;
 case 'Videos': 
      element.label = "Videos"
      element.src = Video

    break;

    case 'eBook.webinar': 
      element.label = "Webinar"
      element.src = Webinar

    break;

    case 'eBook.cheat_sheet': 
      element.label = "Cheat sheet"
      element.src = Cheat_sheet

    break;

    case 'eBook.ebook': 
      element.label = "eBook"
      element.src = Blog
  break;
      
    case 'eBook.infographic': 
      element.label = "Infographic"
      element.src = Infographic
      //icon = Webinar; 
    break;


    //case 'Ebook':  icon = Ebook; break;
    default: //*icon = Blog;*/
    element.label = type
    break;
  }
  // if (type === 'eBook') {
  //   element.label = 'eBook'
  //   if (item?.eBookType === 'cheat_sheet') {
  //     icon = Cheat_sheet
  //     type = item?.eBookType

  //     element.label = "Cheat sheet"
  //     element.src = Cheat_sheet

  //   }
  // }

  const tag = <div className={"bg-blue  w-fit p-2 text-white my-3 text-xs font-bold rounded-r-lg pr-5 " + className}>
    <img src={element.src} alt="resource category icon" className="inline mr-3 max-h-[16px]"  />
    {element.label}
  </div>

  return url ? <Link to={url}>{tag}</Link> : tag


}

export default ResourceTag

