import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Image from "../components/Image";
import ResourceTag from "../components/resource-tag";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { relativeToAbsulote } from "../util/common";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import BreadCrumbs from "../components/resources/breadcrumbs";

function EbookTemplate({ data: { node, posts, site }, location }) {
  const Og = node?.relationships?.OGImage?.localFile?.publicURL;

  const seo = getMetatags(node.metatag, Og);

  const { pdf } = node.relationships;
  //const pdfLink = node.pdfLink
  //console.log(node)
  const type = "eBook." + node.type0;

  const paragraphs = node.relationships.paragraphs.map((p) => ({
    ...p,
    cta: { ...p.cta, uri: pdf?.localFile?.publicURL },
    nid: node.nid,
  }));

  //console.log(paragraphs)

  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-20 lg:h-28"></div>
      <div className="container mx-auto  pb-16 lg:pb-28 space-y-32 lg:space-y-0  ">
        {paragraphs.map((p, index) => (
          <ColsContainer
            key={index}
            flip={index % 2 !== 0}
            left={
              <>
                {index === 0 && (
                  <HeaderHero
                    title={node.title}
                    type={type}
                    location={location}
                    base={site.siteMetadata.siteUrl}
                  />
                )}
                <Card {...p} index={index} type={type} />
              </>
            }
            right={
              <>
                {p?.youTube?.video_id && (
                  <>
                    <Video
                      image={p.relationships.image}
                      alt={p.image?.alt}
                      video_id={p?.youTube?.video_id}
                      className={
                        "mt-8 lg:mt-0 w-full lg:w-auto " +
                        (index === 0 ? " lg:mt-[19rem]" : "")
                      }
                    />
                  </>
                )}

                {!p?.youTube?.video_id && (
                  <Image
                    image={p.relationships.image}
                    alt={p.image?.alt}
                    className={
                      "mt-8 lg:mt-0 " + (index === 0 ? " lg:mt-80" : "")
                    }
                  />
                )}
                {p?.vimeo && (
                  <Vimeo
                    video={p?.vimeo}
                    //autoplay
                    //width="100%"
                    className="w-full"
                  />
                )}
              </>
            }
          />
        ))}
      </div>
    </Layout>
  );
}

const Video = ({ video_id, image, alt, className }) => {
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      loop: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={video_id} opts={opts} className={className} />;
};

const Card = ({ title, description_html, cta, index, nid, type }) => {
  const buttonCss =
    index % 2 === 0 ? " bg-blue text-white " : " border-blue text-blue ";

  const onButtonClick = () => {
    fetch(cta?.uri).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // const parts = pdfLink.split('/')
        // const fileName = parts.at(-1)
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = cta?.uri;
        alink.click();

        window.gtag("event", "download", { href: cta?.uri });
        //sendActionToBackend('download');
      });
    });
  };

  let button =
    index % 2 === 0 ? (
      <button
        onClick={onButtonClick}
        className={
          "border  px-6 py-3 rounded-xl  block-inline resource-download" +
          buttonCss
        }
      >
        {cta?.title}
      </button>
    ) : (
      <OutboundLink
        className={
          "border  px-6 py-3 rounded-xl  block-inline resource-open-in-tab " +
          buttonCss
        }
        href={cta?.uri}
        target="__blank"
      >
        {cta?.title}
      </OutboundLink>
    );

  if (type === "eBook.infographic") {
    button = (
      <OutboundLink
        className={
          "border  px-6 py-3 rounded-xl  block-inline resource-open-in-tab border-blue text-blue"
        }
        href={cta?.uri}
        target="__blank"
      >
        {cta?.title}
      </OutboundLink>
    );
  }

  return (
    <div className="">
      <div className="pb-8">
        <h2 className="text-[24px] lg:text-[24px] leading-[36px] font-bold ">
          {title}
        </h2>
      </div>

      <div
        className="blog-post"
        dangerouslySetInnerHTML={{
          __html: relativeToAbsulote(description_html?.processed),
        }}
      />
      {cta && cta.uri && <div className="mt-8">{button}</div>}
    </div>
  );
};

const HeaderHero = ({ title, type, location, base }) => {
  return (
    <div>
      <div>
        <BreadCrumbs base={base} title={title} />
      </div>

      <div className=" pb-4">
        <ResourceTag type={type} className="" url="/resources/" />
      </div>
      <div>
        <h1 className="text-[32px] lg:text-[40px] leading-[40px] ">{title}</h1>
      </div>
      <div className="flex flex-col lg:flex-row -lg:justify-between my-4 space-y-4 -lg:space-y-0 ">
        <div className="flex flex-row space-x-2 my-4">
          <LinkedinShareButton url={location.href}>
            <img src={Linkedin} alt="Linkedin" width={18} height={18} />
          </LinkedinShareButton>

          <TwitterShareButton url={location.href}>
            <img src={Twitter} alt="Twitter" width={18} height={18} />
          </TwitterShareButton>
        </div>
      </div>
      <div className="h-1 border-b border-gray-600 w-full mt-4 mb-8 -lg:my-10"></div>
    </div>
  );
};

const ColsContainer = ({ left, right, flip = false }) => {
  //console.log('flip', flip)
  return (
    <div
      className={
        "container  flex lg:flex-row flex-col lg:space-x-20 lg:mb-32 mb-12 lg:items-start " +
        (flip ? " flex-col-reverse" : "")
      }
    >
      <div className="lg:w-1/2 ">{flip ? right : left}</div>
      <div className="lg:w-1/2 ">{flip ? left : right}</div>
    </div>
  );
};

export default EbookTemplate;

export const query = graphql`
  query eBook($id: String!) {
    node: nodeEbook(id: { eq: $id }) {
      id
      nid: drupal_internal__nid
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      type0: field_type
      image: field_hero_image {
        alt
      }
      pdfLink: field_text_link

      relationships {
        image: field_hero_image {
          ...Image
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
        paragraphs: field_paragraph_repeater2 {
          ...ParagraphGenericBlock
        }
        pdf: field_pdf_file {
          localFile {
            publicURL
          }
        }
      }
    }
    posts: allNodeBlog {
      nodes {
        id
        title
        path {
          alias
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
